import dayjs from 'dayjs';
import 'dayjs/locale/fr';

export const goneDays = (date: Date): number => {
  const current: number = Date.now();
  const previous: number = date.getTime();

  return Math.ceil((current - previous + 1) / 86400000);
};

export const formatDate = (date: string, dateFormat = 'dddd D MMMM') => {
  dayjs.locale('fr');

  return dayjs(date).format(dateFormat);
};

export const getFirstDayOfLastMonth = (): string => {
  const now = dayjs()
    .subtract(1, 'month')
    .startOf('month')
    .set('hour', 5)
    .set('minute', 0)
    .set('second', 0);

  return now.format(`YYYY-MM-DD`);
};
