import dynamic from 'next/dynamic';
import type { FunctionComponent } from 'react';
import React from 'react';

import type { CarouselContainerProps } from './types';

const DynamicCarousel = dynamic(() => import('./container'));

const Carousel: FunctionComponent<CarouselContainerProps> = (props) => (
  <DynamicCarousel {...props} />
);

export default Carousel;
