import type { FunctionComponent, ReactNode } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import type { NetworkModel } from 'src/models';

import { Container } from 'src/components/ui';

import IconButton from '../Button/IconButton/IconButton';
import CMSContent from '../CMSContent/CMSContent';
import style from './NetworkAd.module.scss';

import { mergeClassNames } from 'src/utils/ReactUtils';

export type NetworkAdProps =
  | {
      title: string;
      children: ReactNode;
    }
  | { networkItems: NetworkModel[] };

const NetworkAd: FunctionComponent<NetworkAdProps> = (props) => {
  const networkItem =
    'networkItems' in props && props.networkItems[0]
      ? props.networkItems[0]
      : null;
  const title = 'title' in props ? props.title : networkItem?.title;
  const children = 'children' in props ? props.children : null;
  const [showEffect, setShowEffect] = useState(false);
  const [isShownButtonMore, setIsShownButtonMore] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const heightOfDescriptionDiv = 200;
  useEffect(() => {
    setTimeout(() => {
      const descriptionHeight = descriptionRef.current?.offsetHeight ?? 0;
      if (descriptionHeight > heightOfDescriptionDiv) {
        setShowEffect(true);
        setIsShownButtonMore(true);
      }
    });
  }, []);

  const handleToggle = (toggleHeight = true) => {
    if (toggleHeight) {
      setIsToggled(!isToggled);
      setShowEffect(!showEffect);
    }
  };

  const toggleIcon: { icon: string; label: string } = !isToggled
    ? { icon: 'fleche-b', label: 'Voir plus' }
    : { icon: 'fleche-h', label: 'Voir moins' };

  return (
    <section className={style.networkSection} data-testid="network_section">
      <Container
        className={mergeClassNames([
          style.network,
          isShownButtonMore && style.networkCursor,
        ])}
        onClick={() => handleToggle(isShownButtonMore)}
      >
        <h2 className={style.networkTitle}>{title}</h2>
        <div
          ref={descriptionRef}
          className={mergeClassNames([
            style.networkDescription,
            showEffect && style.networkDescriptionUnfolded,
          ])}
        >
          {children ?? <CMSContent content={networkItem?.description || ''} />}
          {showEffect && <p className={style.networkReadMore}> </p>}
        </div>
        {isShownButtonMore && (
          <IconButton
            className={`btn ${style.networkBtnReadMore}`}
            leftIcon={toggleIcon.icon}
            onClick={() => handleToggle}
          >
            {toggleIcon.label}
          </IconButton>
        )}
      </Container>
    </section>
  );
};

export default NetworkAd;
