import type { FunctionComponent } from 'react';
import React from 'react';
import TagManager from 'react-gtm-module';
import type { CarServiceBlock, Garage } from 'src/models';
import Carousel from 'src/packages/Carousel';

import { useQuotationEngineContext } from 'src/components/layouts/PublicLayout/components/QuotationEngine/QuotationEngineContext';
import { ShowMoreText, Link, Title2 } from 'src/components/shared';
import { Row } from 'src/components/ui';

import Icon from '../Icon/Icon';
import Responsive from '../Responsive/Responsive';
import ServiceComponent from './Service/Service';
import style from './Services.module.scss';

import { mergeClassNames, usePageType } from 'src/utils/ReactUtils';
import type { SeoTextTag } from 'src/utils/SeoUtils';

const text = `
      Les réparateurs AD s’occupent de la réparation et l’entretien de
      votre voiture, peu importe la marque ou le modèle de celle-ci.
      Profitez d’une prestation de qualité effectuée par des véritables experts auto.
      Réalisez dès maintenant un devis en ligne pour la réparation de votre voiture et obtenez un RDV immédiat !`;

const defaultTitle =
  'Découvrez les prestations proposées par les réparateurs AD';

export type ServicesProps = {
  carServiceBlocks: CarServiceBlock[] | undefined;
  garageSlug?: string;
  garage?: Garage;
  title?: string;
  subTitle?: string;
  hasMoreOffers?: boolean;
  hasReadMore?: boolean;
  titleLevel?: SeoTextTag;
  serviceTitleLevel?: SeoTextTag;
  className?: string;
};

const Services: FunctionComponent<ServicesProps> = ({
  carServiceBlocks,
  garageSlug,
  garage,
  title = defaultTitle,
  subTitle = text,
  hasMoreOffers = true,
  hasReadMore = false,
  titleLevel = 'h2',
  serviceTitleLevel,
  className,
}) => {
  const { openQuotationEngine } = useQuotationEngineContext();

  const TitleTag: keyof JSX.IntrinsicElements = titleLevel;

  const shouldPerformSEOTrick = !!garageSlug;

  const pageType = usePageType();

  const gtmClickEvent = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: pageType,
        eventAction: 'clic encart prestation',
        eventLabel: "plus d'offres",
      },
    });
  };

  const serviceComponents = carServiceBlocks?.map((carServiceBlock) => (
    <ServiceComponent
      titleLevel={serviceTitleLevel}
      isSlider={true}
      key={carServiceBlock.id}
      carServiceBlock={carServiceBlock}
      onClick={() => {
        openQuotationEngine({
          garage,
          categoryId: Number(carServiceBlock.id),
        });
      }}
      hasViewMoreLink={hasReadMore}
    />
  ));

  return (
    <section
      className={mergeClassNames([style.services, className])}
      data-testid="services_block"
    >
      <div className={style.servicesWrapper}>
        <div className={style.servicesHeader}>
          <Title2
            className={style.servicesTitle}
            data-testid="services_block_title"
          >
            <TitleTag className={style.servicesTitleTag}>{title}</TitleTag>
          </Title2>

          <p className={style.servicesDescription}>{subTitle}</p>
          {subTitle !== '' ? (
            <ShowMoreText
              heightLimit={50}
              className={style.servicesDescriptionMobile}
              data-testid="services_block_subtitle"
            >
              {subTitle}
            </ShowMoreText>
          ) : (
            <></>
          )}
        </div>
        <Responsive is={'mobile'}>
          {(carServiceBlocks?.length ?? 0) > 1 ? (
            <Carousel isStretched hasRightEdgeFade hasPagination={false}>
              {serviceComponents}
            </Carousel>
          ) : (
            <Row className={style.servicesList}>{serviceComponents}</Row>
          )}
        </Responsive>
        <Responsive isNot={'mobile'}>
          {(carServiceBlocks?.length ?? 0) > 5 ? (
            <Carousel isStretched hasRightEdgeFade hasPagination={false}>
              {serviceComponents}
            </Carousel>
          ) : (
            <Row className={style.servicesList}>{serviceComponents}</Row>
          )}
        </Responsive>
        {hasMoreOffers ? (
          <div className={style.linkWrapper}>
            <Link
              className={style.servicesLink}
              // SEO trick to avoid linking to the prestation page of the garage
              href="/prestation"
              legacy
              onClick={(e) => {
                if (shouldPerformSEOTrick) {
                  e.preventDefault();

                  window.location.href = `/garage/${garageSlug}/prestation`;
                }

                gtmClickEvent();
              }}
            >
              <Icon
                name="fleche-d"
                height={16}
                width={16}
                className={style.linkIcon}
              />
              <span className={style.linkTxt}>{"Plus d'offres"}</span>
            </Link>
          </div>
        ) : (
          <></>
        )}
      </div>
    </section>
  );
};
export default Services;
