import type { FunctionComponent } from 'react';
import React from 'react';
import TagManager from 'react-gtm-module';
import type { CarServiceBlock } from 'src/models';

import { Link, ButtonSecondary, Image, Price } from 'src/components/shared';

import style from './Service.module.scss';

import { formatPrice } from 'src/utils/JavascriptUtils';
import { mergeClassNames, usePageType } from 'src/utils/ReactUtils';
import type { SeoTextTag } from 'src/utils/SeoUtils';

export type ServiceComponentProps = {
  carServiceBlock: CarServiceBlock;
  isSlider: boolean;
  hasViewMoreLink?: boolean;
  hasCmsBlock?: boolean;
  onClick: (args: {
    categoryId?: number;
    subcategoryId?: number;
    serviceId?: string;
  }) => void;
  titleLevel?: SeoTextTag;
};

const ServiceComponent: FunctionComponent<ServiceComponentProps> = ({
  carServiceBlock,
  isSlider,
  hasViewMoreLink = false,
  hasCmsBlock = true,
  onClick,
  titleLevel = 'h3',
}) => {
  const TitleTag: keyof JSX.IntrinsicElements = titleLevel;

  const pageType = usePageType();

  const gtmClickEvent = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: pageType,
        eventAction: 'clic encart prestation',
        eventLabel: carServiceBlock.name,
        position: carServiceBlock.position,
      },
    });
  };

  const carServiceBlockTitleElement = (
    <TitleTag className={style.headerTitle}>{carServiceBlock.name}</TitleTag>
  );

  return (
    <div
      className={mergeClassNames([isSlider && style.service])}
      data-testid={`service_block_${carServiceBlock.id}`}
    >
      <figure className={style.serviceHeader}>
        <div
          className={mergeClassNames([
            style.serviceImg,
            !hasCmsBlock ? style.serviceImageDarker : '',
          ])}
        >
          <Image
            layout="fill"
            src={carServiceBlock.pictureLink}
            alt={carServiceBlock.pictureAlt ?? carServiceBlock.name}
          />
        </div>
        <figcaption
          className={style.headerLegend}
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: 'eventGA',
                eventCategory: pageType,
                eventAction: 'clic nom prestation',
                eventLabel: carServiceBlock.name,
              },
            });
          }}
        >
          {carServiceBlock.carServicePageUrl ? (
            <Link href={carServiceBlock.carServicePageUrl}>
              {carServiceBlockTitleElement}
            </Link>
          ) : (
            carServiceBlockTitleElement
          )}
        </figcaption>
      </figure>
      <div className={style.serviceBody}>
        {hasCmsBlock && (
          <>
            <div className={style.costWrapper}>
              {carServiceBlock.price && (
                <div>
                  <div className={style.costPrefix}>à partir de</div>
                  <Price
                    className={style.serviceCost}
                    separator={','}
                    price={formatPrice(carServiceBlock.price)}
                  />
                </div>
              )}
            </div>
            <p className={style.bodyDesc}>{carServiceBlock.description}</p>
          </>
        )}

        {hasViewMoreLink && carServiceBlock.carServicePageUrl && (
          <Link
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: 'eventGA',
                  eventCategory: pageType,
                  eventAction: 'clic en savoir +',
                  eventLabel: carServiceBlock.name,
                },
              });
            }}
            href={carServiceBlock.carServicePageUrl}
            className={style.moreInfoLink}
          >
            En savoir +
          </Link>
        )}
        <ButtonSecondary
          onClick={() => {
            gtmClickEvent();
            onClick({ categoryId: Number(carServiceBlock.id) });
          }}
          className={style.bodyBtn}
          data-testid="service_block_button"
        >
          {carServiceBlock.buttonLabel}
        </ButtonSecondary>
      </div>
    </div>
  );
};

export default ServiceComponent;
