import type { FunctionComponent } from 'react';
import React from 'react';
import TagManager from 'react-gtm-module';
import type { NewsModel as NewsModel } from 'src/models';
import Carousel from 'src/packages/Carousel';

import { Image } from 'src/components/shared';
import { Container } from 'src/components/ui';

import Link from '../Link/Link';
import Responsive from '../Responsive/Responsive';
import ShowMoreText from '../ShowMoreText/ShowMoreText';
import styles from './News.module.scss';

import { arrayChunk } from 'src/utils/Array.Utils';
import {
  getDataTestId,
  mergeClassNames,
  usePageType,
} from 'src/utils/ReactUtils';

type NewsProps = {
  newsItems: NewsModel[];
  dataTestId?: string;
  title?: string;
};

const defaultTitle = 'En ce moment dans votre réseau d’entretien auto';

const News: FunctionComponent<NewsProps> = ({
  newsItems,
  dataTestId,
  title = defaultTitle,
}) => {
  const pageType = usePageType();

  const gtmClickEvent = (newsItem: NewsModel) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: pageType,
        eventAction: 'clic encart en ce moment',
        eventLabel: newsItem.title,
        position: newsItems.indexOf(newsItem) + 1,
      },
    });
  };

  const newsCard = (newsItem: NewsModel) => (
    <div
      className={styles.newsSlider}
      key={newsItem.id}
      {...getDataTestId({ dataTestId: 'item', prefix: dataTestId })}
    >
      <div className={styles.newsImageContainer}>
        <Image
          layout={'fill'}
          className={styles.newsSliderImage}
          src={newsItem.icon}
          alt={newsItem.alt ?? ''}
        />
      </div>
      <div className={styles.newsSliderContent}>
        <h3 className={styles.newsSliderContentTitle}>{newsItem.title}</h3>
        <Responsive is="desktop">
          <ShowMoreText heightLimit={85} ellipsis="true">
            {newsItem.description}
          </ShowMoreText>
        </Responsive>
        <Responsive is="mobile">
          <ShowMoreText heightLimit={50} ellipsis="true">
            {newsItem.description}
          </ShowMoreText>
        </Responsive>
        <Link onClick={() => gtmClickEvent(newsItem)} href={newsItem.targetUrl}>
          <button className={styles.newsSliderButton}>
            <span>{newsItem.buttonLabel}</span>
          </button>
        </Link>
      </div>
    </div>
  );

  const newsSliderItems = newsItems.map(newsCard);
  const newsLargeScreenItems = arrayChunk<NewsModel>(newsItems, 2).map(
    (newsItemWrapper, index) => (
      <div
        className={mergeClassNames([
          styles.row,
          newsItems.length === 1 && styles.center,
        ])}
        key={index}
      >
        {newsItemWrapper.map(newsCard)}
      </div>
    ),
  );

  const subtitle =
    'Retrouvez toute l’actualité et les bons plans proposés en ce moment par votre réseau d’entretien et réparation automobile AD.';

  return (
    <div className={styles.news} {...getDataTestId({ dataTestId })}>
      <Container fluid={'lg'}>
        <h2 className={styles.newsTitle}>{title} </h2>
        <p className={styles.newsDescription}>{subtitle}</p>
        <ShowMoreText heightLimit={45} className={styles.newsDescriptionMobile}>
          {subtitle}
        </ShowMoreText>
        <Responsive isOrLarger="largeDesktop">
          <div className={styles.newsWrapper}>{newsLargeScreenItems}</div>
        </Responsive>
        <Responsive isOrSmaller="mediumDesktop">
          <Carousel isStretched hasRightEdgeFade hasPagination={false}>
            {newsSliderItems}
          </Carousel>
        </Responsive>
      </Container>
    </div>
  );
};

export default News;
