import type { FunctionComponent } from 'react';
import React, { useState } from 'react';
import TagManager from 'react-gtm-module';
import type { FeedbackModel } from 'src/models';
import Carousel from 'src/packages/Carousel';

import Container from 'src/components/ui/Container';

import ButtonTertiary from '../Button/ButtonTertiary/ButtonTertiary';
import type { InlineCritizrProps } from '../InlineCritizr/InlineCritizr';
import InlineCritizr from '../InlineCritizr/InlineCritizr';
import Rating from '../Rating/Rating';
import Responsive from '../Responsive/Responsive';
import style from './AvisCritizr.module.scss';

import { goneDays } from 'src/utils/Date.Utils';

import { Title3 } from '..';

import { usePageType } from 'src/utils/ReactUtils';
import type { SeoTextTag } from 'src/utils/SeoUtils';
import { truncate } from 'src/utils/StringUtils';

export type AvisCritizrProps = {
  feedbacks: FeedbackModel[];
  idBaseNetwork?: string;
  title?: string;
  titleLevel?: SeoTextTag;
} & InlineCritizrProps;

const defaultTitle = 'Nos clients nous recommandent';

const AvisCritizr: FunctionComponent<AvisCritizrProps> = ({
  averageRating,
  totalRatings,
  feedbacks,
  idBaseNetwork = 'Web',
  title = defaultTitle,
  titleLevel = 'h3',
}) => {
  const TitleTag: keyof JSX.IntrinsicElements = titleLevel;

  const [openAvisModal, setOpenAvisModal] = useState(false);
  const pageType = usePageType();

  const gtmClickEvent = (eventAction: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: pageType,
        eventAction,
        eventLabel: '/',
        pageType,
      },
    });
  };

  const renderAvis = feedbacks.slice(0, 5).map((avis: FeedbackModel, index) => {
    const gone_days = goneDays(new Date(avis.feedbackDate));

    return (
      <div key={index} className={style.avisListWrapper}>
        <div key={index} className={`d-flex ${style.avisItem}`}>
          <span className={style.avisItemName}>
            {avis.firstName} {avis.lastName}
          </span>
          <span>
            <Rating averageRating={avis.note} />
          </span>
          <p className={style.avisItemComment}>{truncate(avis.comment, 270)}</p>
          <span className={style.avisItemPublish}>
            Publié il y a {gone_days} jours
          </span>
        </div>
      </div>
    );
  });

  return (
    <section className={style.avisSection} data-testid="feedbacks_section">
      <Container fluid className={style.avisContainer}>
        <Title3>
          <TitleTag className={style.title}>{title}</TitleTag>
        </Title3>

        <InlineCritizr
          averageRating={averageRating}
          hasLogo
          totalRatings={totalRatings}
          className={style.avisCritizr}
          actorId={idBaseNetwork}
          modalIsShowed={openAvisModal}
          trackingCallback={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: 'eventGA',
                virtualPagePath: `${pageType}/pop-in/avis-client`,
                virtualPageTitle: 'Avis client',
              },
            });
          }}
        />
        <Responsive isOrLarger="largeDesktop">
          <div className={`d-flex justify-content-center ${style.avisList}`}>
            {renderAvis}
          </div>
        </Responsive>
        <Responsive isOrSmaller="mediumDesktop">
          <Carousel
            isStretched
            hasRightEdgeFade
            edgesClassName={style.edges}
            hasPagination={false}
          >
            {renderAvis}
          </Carousel>
        </Responsive>
        <div className={style.avisButton}>
          <ButtonTertiary
            color={'Brown'}
            onClick={() => {
              setOpenAvisModal(true);
              gtmClickEvent('clic voir tous les avis');
            }}
          >
            Voir tous les avis
          </ButtonTertiary>
        </div>
      </Container>
    </section>
  );
};

export default AvisCritizr;
