import type { FunctionComponent } from 'react';
import React from 'react';
import TagManager from 'react-gtm-module';
import type { PartnerModel } from 'src/models';
import Carousel from 'src/packages/Carousel';

import Image from '../Image/Image';
import Link from '../Link/Link';
import style from './Partners.module.scss';

import { getDataTestId, usePageType } from 'src/utils/ReactUtils';
import type { SeoTextTag } from 'src/utils/SeoUtils';

export type PartnersProps = {
  redirectUrl: string;
  partners: PartnerModel[];
  dataTestId?: string;
  titleLevel?: SeoTextTag;
};

const Partners: FunctionComponent<PartnersProps> = ({
  redirectUrl,
  partners,
  dataTestId,
  titleLevel = 'h3',
}) => {
  const TitleTag: keyof JSX.IntrinsicElements = titleLevel;

  const pageType = usePageType();

  const gtmTracking = (label: string) =>
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventAction: 'clic marques partenaires',
        eventLabel: label,
        pageType,
      },
    });
  const partnersList = partners.map((partner, index) => (
    <Link
      key={index}
      className={style.partnerItem}
      href={redirectUrl}
      onClick={() => gtmTracking(partner.label)}
    >
      <Image
        width={80}
        height={50}
        src={partner.imageUrl}
        alt={partner.imageAlt ?? partner.label}
        className={style.partnerItemBrand}
      />
    </Link>
  ));

  return (
    <section className={style.partners} {...getDataTestId({ dataTestId })}>
      <TitleTag className={style.partnersTitle}>
        Nos équipementiers automobiles
      </TitleTag>
      <div className={style.partnersSlide}>
        <Carousel
          autoPlay
          autoPlayInterval={1000}
          animationDuration={1000}
          hasPagination={false}
          hasArrows={false}
          slidesPerView={8}
        >
          {partnersList}
        </Carousel>
      </div>
    </section>
  );
};

export default Partners;
