import type { FunctionComponent } from 'react';
import React, { useState } from 'react';
import Carousel from 'src/packages/Carousel';

import { Container } from 'src/components/ui';

import { Icon } from '../index';
import Link from '../Link/Link';
import Responsive from '../Responsive/Responsive';
import Title3 from '../Title/Title3/Title3';
import style from './Engagements.module.scss';

import type { EngagementModel } from 'src/models/engagement.model';

import { getDataTestId, mergeClassNames } from 'src/utils/ReactUtils';
import type { SeoTextTag } from 'src/utils/SeoUtils';

export type EngagementsProps = {
  url: string;
  maxLengthDesc: number;
  engagements: EngagementModel[];
  sectionClassName?: string;
  dataTestId?: string;
  minimized?: boolean;
  title?: string;
  titleLevel?: SeoTextTag;
  itemTitleLevel?: SeoTextTag;
};

const defaultTitle = 'Nos Engagements';

const Engagements: FunctionComponent<EngagementsProps> = ({
  engagements,
  url,
  maxLengthDesc,
  sectionClassName,
  dataTestId = 'engagements_section',
  minimized = false,
  title = defaultTitle,
  titleLevel = 'p',
  itemTitleLevel = 'p',
}) => {
  const [mouseEnterStatus, setMouseEnterStatus] = useState(-1);
  const TitleTag: keyof JSX.IntrinsicElements = titleLevel;
  const ItemTitleTag: keyof JSX.IntrinsicElements = itemTitleLevel;

  const suspensionText = (description: string) => {
    let newDescription = description;
    if (description.length > maxLengthDesc) {
      newDescription = description.substr(0, maxLengthDesc) + ' ...';
    }
    return newDescription;
  };
  const renderEngagementItem = (engagement: EngagementModel, index: number) => (
    <Link
      key={index}
      href={`/${url}`}
      className={mergeClassNames([
        style.engagementItem,
        !minimized ? style.engagementItemFull : '',
        `order-${engagement.order}`,
        mouseEnterStatus === index ? 'shadow' : '',
      ])}
      onMouseEnter={() => setMouseEnterStatus(index)}
      onMouseLeave={() => setMouseEnterStatus(-1)}
      data-testid={`${dataTestId}_item`}
      legacy
    >
      <Icon
        name={engagement.icon as never}
        color={'#d9000d'}
        height={minimized ? 35 : 50}
        width={minimized ? 35 : 50}
        className={style.engagementItemIcons}
      />
      <ItemTitleTag className={style.engagementItemTitle}>
        {engagement.primaryTitle}{' '}
      </ItemTitleTag>
      {!minimized && (
        <div
          className={mergeClassNames([
            style.engagementItemDescription,
            mouseEnterStatus === index ? '' : 'd-none',
          ])}
        >
          {suspensionText(engagement.secondaryTitle)}
        </div>
      )}
    </Link>
  );

  const renderEngagements = engagements.map(renderEngagementItem);

  return (
    <section
      className={mergeClassNames([
        style.engagementsSection,
        sectionClassName,
        minimized && style.minimized,
      ])}
      {...getDataTestId({ dataTestId })}
    >
      <Container className={style.engagements}>
        <Title3 className={style.engagementsTitle}>
          <TitleTag>{title}</TitleTag>
        </Title3>
        <Responsive is="mobile">
          <Carousel
            isStretched
            hasRightEdgeFade
            edgesClassName={style.edges}
            hasPagination={false}
          >
            {renderEngagements}
          </Carousel>
        </Responsive>
        <Responsive is="desktop">
          <div className={style.engagementsContainer}>{renderEngagements}</div>
        </Responsive>
      </Container>
    </section>
  );
};

export default Engagements;
