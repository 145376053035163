import type { FunctionComponent } from 'react';
import React from 'react';

import { Image } from 'src/components/shared/index';

import { mergeClassNames } from '../../../utils/ReactUtils';
import style from './Promise.module.scss';

export type PromiseProps = {
  imageUrl: string;
  title: string;
  text1: string;
  text2: string;
  detail?: string;
  isGarage?: boolean;
};

const Promise: FunctionComponent<PromiseProps> = ({
  imageUrl,
  title,
  text1,
  text2,
  detail,
  isGarage,
}) => {
  return (
    <div
      className={mergeClassNames(['d-flex', isGarage && style.promiseGarage])}
    >
      <div className={style.promiseImage}>
        <Image
          src={imageUrl}
          layout="fill"
          alt="devis réparation auto en ligne"
          priority
        />
      </div>
      <div className={style.promise}>
        <h3 className={`d-none d-sm-block ${style.promiseTitle}`}>{title}</h3>
        <div className={style.textWrapper}>
          <div className={`${style.promiseText} ${style.promiseTextRed}`}>
            {text1}
          </div>
          <div className={`${style.promiseText} ${style.promiseTextBrown}`}>
            {text2}
          </div>
        </div>
        <h3 className={`d-none d-xl-block ${style.promiseDetail}`}>{detail}</h3>
      </div>
    </div>
  );
};

export default Promise;
